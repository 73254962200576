import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'restaurateur',
    loadChildren: () => import('./pages/restaurateur/restaurateur.module').then( m => m.RestaurateurPageModule)
  },
  {
    path: 'ars',
    loadChildren: () => import('./pages/ars/ars.module').then( m => m.ARSPageModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./pages/client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'netexpo',
    loadChildren: () => import('./pages/netexpo/netexpo.module').then( m => m.NetexpoPageModule)
  },
  {
    path: 'lavomasque',
    loadChildren: () => import('./pages/lavomasque/lavomasque.module').then( m => m.LavomasquePageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./pages/info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'assistance',
    loadChildren: () => import('./pages/assistance/assistance.module').then( m => m.AssistancePageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
